import { API_URL } from "../../utils/constants";
import Cookies from 'universal-cookie';
export async function deleteapi(url_param,data) {
    const cookies = new Cookies();
    var token = await cookies.get('user_id');
    AbortSignal.timeout ??= function timeout(ms) {
        const ctrl = new AbortController()
        setTimeout(() => {
            ctrl.close()
            // console.log('reached');
        }, ms)
        return ctrl.signal
    }
    let h={}
    // let url = "https://api.rapidmock.com/mocks/89mEw";
    if (token !== "")
        h = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    else {
        h = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + payload.token
        }
    }
    let url = API_URL + url_param ;
    // let url = "https://api.rapidmock.com/mocks/89mEw";
    let resp = await fetch(url, {
        signal: AbortSignal.timeout(5000),
        method: 'DELETE',
        headers: h,
        body: JSON.stringify(data)
    });
    let res = await resp.json();

    return res;

}