/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import logo from '../cc.png'
import { Layout, Menu } from 'antd';
import {
    AppstoreOutlined, DashboardOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import { API_URL } from '../utils/constants';
import { getapis } from '../pages/apis/getapi';

const { Sider } = Layout;

export default function Sidebar({ collapse, handleCollapse }) {

    const [itemList, setitemList] = useState([])
    let navigate = useNavigate();
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    function run(data) {
       
        data.forEach(item => {
            setitemList(itemList => [...itemList,
            getItem(item.location, item.location, <AppstoreOutlined />, makearray(item.domains))
            ])
        })
        setitemList(item => [...item, getItem('Admin Access', 'grp', null, [getItem('Admin Dashboard', 'admin-dashboard',<DashboardOutlined />)], 'group')])
        function makearray(i) {
            const g = [];
            i.forEach((ig, index) => {
                g.push(getItem(ig, ig, <DashboardOutlined />))
            })
            return g;
        }
    }
    const get_nav_menu_items = async ()=>{
        // let url = API_URL + "api/getcitieswithdomains";
        // let resp = await fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         // 'Authorization': 'Bearer ' + payload.token
        //     },
        //     // body: JSON.stringify(data)
        // });
        const res = await getapis('api/getcitieswithdomains');
        // let res = await cities.json();
        // console.log(res)
        if(res.length>0){
            run(res)
        }else{}
    }

    useEffect(() => {
        get_nav_menu_items()  
        // console.log('render')
    }, [])

    // const items = [
    //     getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
    //         getItem('Option 5', '5'),
    //         getItem('Option 6', '6'),
    //         getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
    //     ]),
    //     getItem('Navigation Three', 'sub4', <SettingOutlined />, [
    //         getItem('Option 9', '9'),
    //         getItem('Option 10', '10'),
    //         getItem('Option 11', '11'),
    //         getItem('Option 12', '12'),
    //     ]),
    //     getItem('Group', 'grp', null, [getItem('Option 13', '13'), getItem('Option 14', '14')], 'group'),
    // ];
    const onClick = (e) => {
        // console.log('click ', e);
        if (e.keyPath[0] === 'admin-dashboard') {
            navigate(`/admin/dashboard`, { replace: true })
        } else {

            navigate(`/${e.keyPath[1].replace(" ", "-")}/${e.keyPath[0].replace(" ", "-")}`, { replace: true })
        }
    };
    return (
        <Sider theme='light' trigger={null} collapsible collapsed={collapse} onCollapse={handleCollapse} breakpoint="lg"
        width={240}
            collapsedWidth="0"
            onBreakpoint={(broken) => {
                // console.log(broken);
            }}>
            <div className="logo">
                <img src={logo} alt="" width={240} height={64} />
            </div>
            <Menu
                onClick={onClick}
                defaultSelectedKeys={['5']}
                defaultOpenKeys={['sub1']}
                mode="inline"
                items={itemList}
            />
        </Sider>
    )
}
