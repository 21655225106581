/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Dashboard from './pages/dashboard'
import { Routes, Route } from "react-router-dom";
import HelloComp from './components/Hello';
import Err from './components/Err';
import Website from './pages/Website';
import AdminPage from './pages/AdminPage';

import Profile from './pages/Profile';
import Blog from './pages/Blog';
import Comment from './pages/Comment';
import PremiumSocial from './pages/PremiumSocial';
import Social from './pages/Social';
import Edulink from './pages/Edulink';
import Redirect from './pages/Redirect';
import Directory from './pages/Directory';
import Adpost from './pages/Adpost';
import Sensitive from './pages/Sensitive';
import Login from './pages/login';
import { getapis } from './pages/apis/getapi';
// import Cookies from 'universal-cookie';
import { Layout, Spin } from 'antd';
export default function App() {
  // eslint-disable-next-line no-unused-vars
  const [isLoggedin, setisLoggedin] = useState("loading")
  // const cookies = new Cookies();
  // cookies.set('user_id', '12345', { path: '/' });
  const verifyToken = async () => {
    try {
      // var g = await cookies.get('user_id');
      // console.log(g);
      const res = await getapis('api/auth/verifytoken');
      if (res.success) {
        setisLoggedin(true)
      } else {
        setisLoggedin(false)
      }
    } catch (err) {
      console.log('err ', err);
      setisLoggedin(false)
    }
  }
  const handleLoginChange = (r) => {
    setisLoggedin(r)
  }
  useEffect(() => {

    verifyToken()
    return () => {
    }
  }, [])
  if (isLoggedin === "loading") {
    return (
      <Layout style={{ width: '100vw', height: '100vh',justifyContent:'center' }}>
        <Spin tip="RUKO BC LOAD HORA H...">
          
        </Spin>
      </Layout>
    )
  }
  if (isLoggedin) {
    return (
      <Routes>
        <Route path="/" element={<Dashboard children={<HelloComp />} />} />
        <Route path="/admin/dashboard" element={<Dashboard children={<AdminPage />} />} />
        <Route path="/:location/:id" element={<Dashboard children={<Website />} />} />
        <Route path="/:location/:id/premium-social/" element={<Dashboard children={<PremiumSocial />} />} />
        <Route path="/:location/:id/profile/" element={<Dashboard children={<Profile />} />} />
        <Route path="/:location/:id/blog/" element={<Dashboard children={<Blog />} />} />
        <Route path="/:location/:id/comment/" element={<Dashboard children={<Comment />} />} />


        {/* Added 17-12-2022  */}
        <Route path="/:location/:id/social/" element={<Dashboard children={<Social />} />} />
        <Route path="/:location/:id/edulink/" element={<Dashboard children={<Edulink />} />} />
        <Route path="/:location/:id/redirect/" element={<Dashboard children={<Redirect />} />} />
        <Route path="/:location/:id/directory/" element={<Dashboard children={<Directory />} />} />
        <Route path="/:location/:id/adpost/" element={<Dashboard children={<Adpost />} />} />
        <Route path="/:location/:id/sensitive/" element={<Dashboard children={<Sensitive />} />} />

        <Route path="*" element={<Dashboard children={<Err />} />} />
      </Routes>

    )
  } else {
    return <Login handleLoginChange={handleLoginChange} />
  }

}
