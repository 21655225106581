import React from 'react';
import { Button, Checkbox, Form, Input, Layout } from 'antd';
// import { Typography } from 'antd';
import { postapi } from './apis/postapi';
import Cookies from 'universal-cookie';



export default function Login({ handleLoginChange }) {
  // const { Title } = Typography;
  const cookies = new Cookies();
  const onFinish = (values) => {
    loginUser(values)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const loginUser = async (data) => {
    const g = {
      username: data.username,
      password: data.password
    }
    try {
      // console.log(g);
      const res = await postapi('api/auth/login', g);
      if (res.success) {
        await cookies.set('user_id', res.token, { path: '/' });
        handleLoginChange(true)
       
      }
    } catch (err) {
      console.log('err ', err);
      handleLoginChange(false)
    }
  }
  return (
    <Layout style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <h1>DZSLINKS</h1>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Layout>

  );
}