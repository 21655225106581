/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputNumber, Popconfirm, Space, Table, Typography, message, Button } from 'antd';
import { editeapi } from '../pages/apis/editapi';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { deleteapi } from '../pages/apis/deleteapi';

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const TableComp = ({ originData, type }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const searchInput = useRef(null);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    useEffect(() => {
        // console.log('remder ', originData);
        setData(originData)
        return () => {
        }
    }, [originData])
    // console.log('dd ',data);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        form.setFieldsValue({
            livelink: '',
            email: '',
            da_pa: '',
            username: '',
            password: '',
            date: '',
            comment: '',
            listed_by: '',
            index_status: '',
            // social_type: '',
            ...record,
        });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const success = (c = "This is a success message") => {
        messageApi.open({
            type: 'success',
            content: c,
        });
    };
    const error = (c = "This is an error message") => {
        messageApi.open({
            type: 'error',
            content: c,
        });
    };
    const del = async (key) => {
        // console.log("k ",key.id)
        try {
            const result = await deleteapi('api/listing/deletelisting', { id: key.id })
            console.log(result)
            success("deleted successfully")
            const p = data.filter(i => i.id != key.id)
            setData(p)
        } catch (err) {
            error("unable to delete")
        }
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        // onClick={() => clearFilters && handleReset(clearFilters)}
                        onClick={() => clearFilters()}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const saveediteddata = async (body, id) => {
        // return
        try {
            await editeapi('api/listing/editlisting', { body: body, id: id });
            messageApi.open({
                type: 'success',
                content: 'Saved !',
            });
        } catch (err) {
            messageApi.open({
                type: 'error',
                content: 'Unable to save edited data',
            });
        }
    }
    const save = async (key) => {
        // console.log("EED KE ", editingKey)
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                // console.log("ROW EDItED ", row)
                setData(newData);
                // console.log("IF KE ", data[index].id)
                setEditingKey('');
                saveediteddata(row, data[index].id)
            } else {
                newData.push(row);
                // console.log("ELSE KE ", row)
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const setCopyclip = async (e) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(e);
            messageApi.open({
                type: 'success',
                content: 'Copied !',
            });
        } else {
            return document.execCommand('copy', true, e);
        }

    }
    const columns = [
        {
            title: 'livelink',
            dataIndex: 'livelink',
            width: '60%',
            editable: true,
            ...getColumnSearchProps('livelink'),
            render: (_, record) => {
                return <>
                    <a href={record.livelink} target="_blank" rel="noreferrer">{record.livelink}</a>
                    <CopyOutlined style={{ marginLeft: 10, cursor: 'pointer', color: "#ccc" }} onClick={() => setCopyclip(record.livelink)} />
                </>
            }
        },
        {
            title: 'email',
            dataIndex: 'email',
            width: '35%',
            editable: true,
            render: (_, record) => {
                return <>
                    <a href='#'>{record.email}</a>
                    <CopyOutlined style={{ marginLeft: 10, cursor: 'pointer', color: "#ccc" }} onClick={() => setCopyclip(record.email)} />
                </>
            }
        },
        {
            title: 'da_pa',
            dataIndex: 'da_pa',
            width: '15%',
            editable: true,
        },
        {
            title: 'username',
            dataIndex: 'username',
            width: '30%',
            editable: true,
            render: (_, record) => {
                return <>
                    <a href='#'>{record.username}</a>
                    <CopyOutlined style={{ marginLeft: 10, cursor: 'pointer', color: "#ccc" }} onClick={() => setCopyclip(record.username)} />
                </>
            }
        },
        {
            title: 'password',
            dataIndex: 'password',
            width: '27%',
            editable: true,
            render: (_, record) => {
                return <>
                    <a href='#'>{record.password}</a>
                    <CopyOutlined style={{ marginLeft: 10, cursor: 'pointer', color: "#ccc" }} onClick={() => setCopyclip(record.password)} />
                </>
            }
        },
        {
            title: 'date',
            dataIndex: 'date',
            width: '15%',
            editable: true,
        },
        {
            title: 'comment',
            dataIndex: 'comment',
            width: '15%',
            editable: true,
        },
        {
            title: 'listed_by',
            dataIndex: 'listed_by',
            width: '12%',
            editable: true,
        },

        {
            title: 'social_type',
            dataIndex: 'social_type',
            width: '15%',
            editable: true,
        },
        {
            title: 'index_status',
            dataIndex: 'index_status',
            width: '15%',
            // fixed: 'right',
            editable: true,
            sorter: (a, b) => a.index_status == "true",
            render: (_, record) => {
                return <>
                    {
                        record.index_status == "true" ? <h1 style={{ color: "green" }}>{record.index_status}</h1> : <h1 style={{ color: "red" }}>{record.index_status}</h1>
                    }

                </>
            }
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            width: '20%',
            fixed: 'right',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>

                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Space size="middle">
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => del(record)}>
                            <a>Delete</a>
                        </Popconfirm>
                    </Space>



                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                // key:record.key,
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <Form form={form} component={false} >
            {contextHolder}
            <Table
                expandable={true}
                rowKey={record => record.key}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered={true}
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
                scroll={{
                    x: 1500,
                    y: 400,
                }}
                style={{
                    minHeight: 200
                }}
            />
        </Form>
    );
};
export default TableComp;
