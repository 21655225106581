/* eslint-disable no-template-curly-in-string */
import React from 'react'
import { capitalizeFirstLetter } from '../utils/utils'

import {  Breadcrumb } from 'antd'
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Bcrumb({id,location,page}) {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
                <a href={`/${location}`}>{capitalizeFirstLetter(location)}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <a href={`/${location}/${id}`}>{id}</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item> {page}</Breadcrumb.Item>
        </Breadcrumb>
    )
}
