/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CommentOutlined, EyeOutlined, LineChartOutlined, LinkOutlined, MailOutlined, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Drawer, Input, Select, Space } from 'antd'
import { useParams } from 'react-router-dom';
import { postapi } from '../pages/apis/postapi';
import { getapis } from '../pages/apis/getapi';


export default function DrawerComp({ showDrawer, handleparentaddShow, type, openNotificationWithIcon, handleadddata }) {
    let { location, id } = useParams();
    const [open, setOpen] = useState(false);
    const [livelink, setlivelink] = useState("")
    const [social, setsocial] = useState("")
    const [socialselect, setsocialselect] = useState([])
    const [indexstatus, setindexstatus] = useState("")
    const [email, setemail] = useState("")
    const [dapa, setdapa] = useState("")
    const [username, setusername] = useState("")
    const [password, setpassword] = useState("")
    const [date, setdate] = useState("")
    const [listedby, setlistedby] = useState("")
    const [comment, setcomment] = useState("")
    const [cityname, setcityname] = useState("")
    const [domainname, setdomainname] = useState("")
    const [category, setcategory] = useState("")
    const handleSubmit = async (e) => {
        const body = {
            city_name: cityname,
            domain_name: domainname,
            livelink: livelink,
            email: email,
            da_pa: dapa,
            username: username,
            password: password,
            date: date,
            listed_by: listedby,
            comment: comment,
            index_status: indexstatus === "true" ? true : false,
            social_type: social,
            category: category,
        }
        try {
            const result = await postapi('api/listing/addlisting', body)
            if (result.status === true) {
                openNotificationWithIcon('success', 'Listing added successfully')
                handleadddata({
                    key: result.result._id,
                    id: result.result._id,
                    livelink: result.result.livelink,
                    email: result.result.email,
                    da_pa: result.result.da_pa,
                    username: result.result.username,
                    password: result.result.password,
                    date: result.result.date,
                    listed_by: result.result.listed_by,
                    comment: result.result.comment,
                    index_status: result.result.index_status,
                    social_type: result.result.social_type,
                    category: result.result.category,
                })
                setlivelink('')
                setemail('')
                setdapa('')
                setusername('')
                setpassword('')
                setlistedby('')
                setindexstatus('')
                setcomment('')
            } else {
                openNotificationWithIcon('error', result.msg?.message || result.err || "Validation error")

            }
            setOpen(false);
            handleparentaddShow()
        } catch (e) {
            console.log('e ',e);
            // openNotificationWithIcon('error', e.msg.message || "Validation error")
            setOpen(false);
            handleparentaddShow()
        }

    }
    const onClose = () => {
        setOpen(false);
        handleparentaddShow()
    };
    const handleSelectChange = (e) => {
        setsocial(e)
    }
    const getsocialmedia = async () => {
        try {
            const soc = await getapis('api/social/getsocial');
            const slc = soc.map(item => {
                return [
                    {
                        label: item.social_name,
                        value: item.social_name
                    }
                ]
            }).flat(1)
            // console.log(slc);
            setsocialselect(slc)
        } catch (err) {
            console.log('err ', err);
            openNotificationWithIcon('error', 'failed', 'could not get social media types')
        }
    }
    useEffect(() => {
        getsocialmedia()
        // console.log('location ', location)
        // console.log('id  ', id)
        // console.log('type  ', type)
        setcityname(location)
        setdomainname(id)
        setcategory(type)
        setOpen(showDrawer)
    }, [showDrawer])

    return (
        <Drawer
            title={"Add " + type.replace('-', ' ') + " backlink"}
            placement={'right'}
            width={400}
            onClose={onClose}
            open={open}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" onClick={handleSubmit}>
                        Add
                    </Button>
                </Space>
            }
        >

            <Input placeholder="Live Link" prefix={<LinkOutlined />} value={livelink} onChange={(e) => setlivelink(e.target.value)} />
            <Divider />
            <Space direction='horizontal'>
                {
                    type === 'premium-social' &&
                    <Select
                        defaultValue="Select social media"
                        style={{ width: 160 }}
                        onChange={handleSelectChange}
                        options={socialselect}
                    />
                }
                <Select

                    defaultValue="Index status"
                    style={{ width: 120 }}

                    onChange={(e) => setindexstatus(e)}
                    options={[
                        {
                            value: 'true',
                            label: 'true',
                        },
                        {
                            value: 'false',
                            label: 'false',
                        },
                    ]}
                />
                {/* <Input placeholder="Index Status" prefix={<RiseOutlined />} value={indexstatus} onChange={(e) => setindexstatus(e.target.value)} /> */}
            </Space>

            <Divider />
            <Space direction='horizontal'>
                <Input placeholder="Email" prefix={<MailOutlined />} type="email" value={email} onChange={(e) => setemail(e.target.value)} />
                <Input placeholder="DA/PA" prefix={<LineChartOutlined />} value={dapa} onChange={(e) => setdapa(e.target.value)} />
            </Space>
            <Divider />
            <Space direction='horizontal'>
                <Input placeholder="Username" prefix={<UserAddOutlined />} value={username} onChange={(e) => setusername(e.target.value)} />
                <Input placeholder="Password" prefix={<EyeOutlined />} type="password" value={password} onChange={(e) => setpassword(e.target.value)} />
            </Space>
            <Divider />
            <Space direction='horizontal'>
                <DatePicker onChange={(date, dateString) => {
                    var d = dateString.split('-')
                    setdate(d[2] + '-' + d[1] + '-' + d[0])
                    console.log(date, dateString);
                    
                }} />
                <Input placeholder="Listed by" prefix={<UsergroupAddOutlined />} value={listedby} onChange={(e) => setlistedby(e.target.value)} />
            </Space>
            <Divider />
            <Input placeholder="Comment" prefix={<CommentOutlined />} value={comment} onChange={(e) => setcomment(e.target.value)} />

        </Drawer>
    )
}
