import React, { useState } from 'react';
import { Button } from 'antd';
import 'antd/dist/reset.css';
import '../index.css';
import {
    MenuOutlined,
    PoweroffOutlined,
} from '@ant-design/icons';
import { Layout } from 'antd';
import Sidebar from '../components/Sidebar';
import Cookies from 'universal-cookie';

const { Header, Content, Footer } = Layout;

export default function Dashboard({ children }) {

    const [collapsed, setCollapsed] = useState(false);
    const handleCollapse = () => {
        setCollapsed(!collapsed)
    }
    const logoutuser =async ()=>{
        const cookies = new Cookies();
        await cookies.remove('user_id',{ path: '/' });
        window.location.reload()
    }
    return (
        <div className="App">
            {/* <Layout style={{ height: "100vh" }}> */}
            <Layout style={{ minHeight:'100vh' }}>
                <Sidebar handleCollapse={handleCollapse} collapse={collapsed} />
                <Layout className="site-layout" theme='light'>
                    <Header
                        className="site-layout-background"
                        style={{
                            padding: 0,
                        }}
                    >
                        {React.createElement(collapsed ? MenuOutlined : MenuOutlined, {
                            className: 'trigger',
                            onClick: () => setCollapsed(!collapsed),
                        })}

                        <Button type="secondary" shape="circle" icon={<PoweroffOutlined />} style={{ float: 'right', width: '60px', height: '60px', fontSize: '20px', color: "#fff",cursor:'pointer' }} onClick={()=>logoutuser()}/>

                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 15,
                            minHeight: 280,
                        }}
                    >
                        {children}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>DZSLINKS ©{new Date().getFullYear()} Created by Digitalzillas</Footer>
                </Layout>
            </Layout>
        </div>
    );
}
