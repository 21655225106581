/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import { FileAddOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Select, Skeleton, Space, notification, Modal } from 'antd'
import Dragger from 'antd/es/upload/Dragger';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Bcrumb from '../components/Breadcrumb';
import DrawerComp from '../components/DrawerComp';
import TableComp from '../components/Table';
import { postapi } from './apis/postapi';

export default function Directory() {
    let { location, id } = useParams();
    const [loading, setLoading] = useState(true)
    const [showDrawer, setShowDrawer] = useState(false)
    const [data, setData] = useState([])
    const [file, setfile] = useState([])
    const [array, setarray] = useState([])
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const fileReader = new FileReader()
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        // console.log(ref.current)
        setIsModalOpen(false);
    };
    const handleOk = () => {
        try {
            if (file) {
                fileReader.onload = function (event) {
                    const text = event.target.result;
                    csvFileToArray(text);
                };
                fileReader.readAsText(file);
                setIsModalOpen(false);
                
            } else {
                console.log('err f');
            }
        } catch (err) {
            console.log('err');
            openNotificationWithIcon('error', 'File missing', 'No file selected')
        }


    };
    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        const array = csvRows.map(i => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });
        uploadData(array)
        setarray(array);
    };
    const openNotificationWithIcon = (type, title, descr) => {
        api[type]({
            message: title,
            description: descr,
            duration: 2,
        });
    };
    const uploadData = async (arr) => {
        console.log(arr);
        const g = arr.filter(item => item.livelink !== "" && item.email !== "").map(item => [{
            city_name: location,
            domain_name: id,
            livelink: item.livelink,
            category: "directory",
            da_pa: item.da_pa,
            email: item.email,
            username: item.username,
            password: item.password,
            date: item.date,
            comment: item.comment,
            listed_by: item.listed_by,
            index_status: item.index_status == "TRUE" ? true : false,
            social_type: item.social_type,
        }]).flat(1)
        try {
            // const p = [];
            const rrr = await postapi('api/listing/addlistingbycsv', g)
            const p = rrr.result.map((item, index) => [{
                key: data.length + index,
                id: item._id,
                livelink: item.livelink,
                email: item.email,
                da_pa: item.da_pa,
                username: item.username,
                password: item.password,
                date: item.date,
                comment: item.comment,
                listed_by: item.listed_by,
                index_status: item.index_status.toString(),
                social_type: item.social_type,
            }]).flat(1);
            // console.log("p ",p);
            // setData(d => [...d, ...p])
            window.location.reload() 
        } catch (err) {
            openNotificationWithIcon('error', 'File upload failed', 'There was an error uploading the file')
        }
    }
    const props = {
        name: 'file',
        multiple: false,
        onChange(info) {
            const { status } = info.file;

            if (status === 'done') {
                openNotificationWithIcon('success', 'Success', `${info.file.name} file added successfully`)
            } else if (status === 'error') {
                openNotificationWithIcon('error', 'Failed', 'file upload failed')
            }
        },
        beforeUpload: (file) => {
            setfile(file)
            return false;
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove(file){
            console.log('rem ',file);
        },
        
    };
    
    const handleparentaddShow = (e) => {
        // console.log(e)
        setShowDrawer(false)
    }
    const handleshowDrawer = (e) => {
        setShowDrawer(true)
    }
    const getallsociallisting = async () => {
        try {
            const result = await postapi('api/listing/getlisting', { domain_name: id, category: "directory" })
            const p = [];

            result.result.forEach((item, index) => {
                p.push({
                    key: index,
                    id: item._id,
                    livelink: item.livelink,
                    email: item.email,
                    da_pa: item.da_pa,
                    username: item.username,
                    password: item.password,
                    date: item.date,
                    comment: item.comment,
                    listed_by: item.listed_by,
                    index_status: item.index_status.toString(),
                    social_type: "directory",
                })
            });
            // console.log('p ', p);
            setData(p)
            setLoading(false)
        } catch (err) {
            openNotificationWithIcon('error', 'Failed', 'Cannot get data')
            setLoading(false)
        }
    }
    const handleadddata = async (data) => {
        console.log(' D D ', data);
        setData(d => [...d, data])
    }
    useEffect(() => {
        getallsociallisting()
    }, [showDrawer])

    return (
        <div>
            {contextHolder}
            <Row gutter={[10, 20]} style={{ marginBottom: 20 }} >
                <Col span={24}>
                    <Bcrumb id={id} location={location} page="Directory" />
                </Col>
            </Row>
            <Space direction="horizontal" wrap>
                <Space wrap>
                    <Button type="primary" icon={<UploadOutlined />} size={24} onClick={handleshowDrawer}>
                        Add item
                    </Button>
                </Space>
                <Space wrap>
                    <Button type="primary" icon={<FileAddOutlined />} size={24} onClick={showModal}>
                        Upload CSV
                    </Button>
                </Space>
            </Space>
            <Divider />
            <Row>
                <Col span={24}>
                    {
                        loading ? <Skeleton active /> : <TableComp originData={data} type="directory"/>
                    }
                </Col>
            </Row>
            <DrawerComp showDrawer={showDrawer} handleparentaddShow={handleparentaddShow} openNotificationWithIcon={openNotificationWithIcon} type="directory" handleadddata={handleadddata} />
            <Modal title="Add csv file" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Dragger {...props} >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                    </p>
                </Dragger>
            </Modal>
        </div>
    )
}