import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
// require('dotenv').config()
const root = ReactDOM.createRoot(document.getElementById('root'));
const strictMode = process.env.NODE_ENV === 'production';
root.render(
  (strictMode && (
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#398378",
          },
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>

      </ConfigProvider>
    </React.StrictMode>
  )) || <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#398378",
      },
    }}
  > <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConfigProvider>

);


