/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { EyeOutlined, LinkOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';
// import { API_URL } from '../utils/constants';
import { postapi } from './apis/postapi';

export default function Website() {
    let { location, id } = useParams();
    let navigate = useNavigate();
    const [premiumsocialcount, setpremiumsocialcount] = useState(0)
    const [profilecount, setprofilecount] = useState(0)
    const [blog_count, setblogcount] = useState(0)
    const [comment_count, setcommentcount] = useState(0)
    const [social_count, setsocialcount] = useState(0)
    const [edulink_count, setedulinkcount] = useState(0)
    const [redirect_count, setredirectcount] = useState(0)
    const [directory_count, setdirectorycount] = useState(0)
    const [adpost_count, setadpostcount] = useState(0)
    const [sensitive_count, setsensitivecount] = useState(0)
    const [loading, setLoading] = useState(false);

    const getsociallistcount = async () => {
        setpremiumsocialcount(0)
        setblogcount(0) 
        setcommentcount(0) 
        setprofilecount(0)
        setsocialcount(0)
        setedulinkcount(0)
        setredirectcount(0)
        setdirectorycount(0)
        setadpostcount(0)
        setsensitivecount(0)
        setLoading(true)
        try {
            const result = await postapi('api/listing/countlisting', { domain_name: id })
            if (Object.keys(result)) {
                if(result.premium_count.length>0)
                    setpremiumsocialcount(result.premium_count[0].count_premium)
                if(result.blog_count.length>0)
                    setblogcount(result.blog_count[0].count_blog)
                if(result.profile_count.length>0)
                    setprofilecount(result.profile_count[0].count_profile)
                if(result.comment_count.length>0)
                    setcommentcount(result.comment_count[0].count_comment)
                if(result.social_count.length>0)
                    setsocialcount(result.social_count[0].count_social)
                if(result.edulink_count.length>0)
                    setedulinkcount(result.edulink_count[0].count_edulink)
                if(result.redirect_count.length>0)
                    setredirectcount(result.redirect_count[0].count_redirect)
                if(result.directory_count.length>0)
                    setdirectorycount(result.directory_count[0].count_directory)
                if(result.adpost_count.length>0)
                    setadpostcount(result.adpost_count[0].count_adpost)
                if(result.sensitive_count.length>0)
                    setsensitivecount(result.sensitive_count[0].count_sensitive)
                
            }
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }
    useEffect(() => {
        // fetchreq()
        getsociallistcount()
        console.log('location ', location, ' id', id);
    },[location,id])
    AbortSignal.timeout ??= function timeout(ms) {
        const ctrl = new AbortController()
        setTimeout(() => {
            ctrl.close()
            // console.log('reached');
        }, ms)
        return ctrl.signal
    }   
    return (
        <div className="site-statistic-demo-card">
            <Row gutter={16}  >
                <Col md={6} sm={6} xs={12} >
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/premium-social/`);
                            }} />
                        ]}>
                        <Statistic
                            title="Premium Social"
                            value={premiumsocialcount}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}


                        />

                    </Card>
                </Col>

                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/profile/`);
                            }} />
                        ]}>
                        <Statistic
                            title="Profile"
                            value={profilecount}
                            precision={0}
                            valueStyle={{ color: 'red' }}
                            suffix={<LinkOutlined />}
                        />
                        {/* <Button
                            style={{
                                marginTop: 16,
                            }}
                            type="primary"
                            
                        >
                            View
                        </Button> */}
                    </Card>
                </Col>

                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/blog/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Blog"
                            value={blog_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}

                        />
                        {/* <Button
                            style={{
                                marginTop: 16,
                            }}
                            type="primary"
                            
                        >
                            View
                        </Button> */}
                    </Card>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/comment/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Comment"
                            value={comment_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}

                        />
                    </Card>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/social/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Social"
                            value={social_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}

                        />
                    </Card>
                </Col>
                
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/edulink/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Edulink"
                            value={edulink_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}
                        />
                    </Card>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/redirect/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Redirect"
                            value={redirect_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}
                        />
                    </Card>
                </Col>
                
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/directory/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Directory"
                            value={directory_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}
                        />
                    </Card>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/adpost/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Ad post"
                            value={adpost_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}
                        />
                    </Card>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Card
                        loading={loading}
                        actions={[
                            <EyeOutlined key='eye' onClick={() => {
                                return navigate(`/${location}/${id}/sensitive/`);
                            }} />
                        ]}>

                        <Statistic
                            title="Sensitive"
                            value={sensitive_count}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            suffix={<LinkOutlined />}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
