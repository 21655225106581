/* eslint-disable jsx-a11y/anchor-is-valid */
import { CodeOutlined, DingdingOutlined, FileAddOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, List, Modal, Row, Select, Statistic, notification, Popconfirm } from 'antd';
import React, { useEffect, useState, memo } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Bcrumb from '../components/Breadcrumb';
import { deleteapi } from './apis/deleteapi';
import { getapis } from './apis/getapi';
import { postapi } from './apis/postapi';

function AdminPage() {
    let { id, location } = useParams();
    let navigate = useNavigate();
    const [loading, setloading] = useState(true)
    const [addcityModal, setaddcityModal] = useState(false)
    const [adddomainModal, setaddomainMdoal] = useState(false)
    const [adddsocialModal, setaddSocialMdoal] = useState(false)
    const [inpcity, setinpcity] = useState("")
    const [inpdomain, setinpdomain] = useState("")
    const [inpsocial, setinpsocial] = useState("")
    const [citydata, setcitydata] = useState([])
    const [citydropdown, setcitydropdown] = useState([])
    const [socialdata, setSocialData] = useState([])
    const [domaindata, setdomaindata] = useState([])
    const [selectvalue, setselectvalue] = useState("")
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, title, descr) => {
        api[type]({
            message: title,
            description: descr,
            duration: 2,
        });
    };

    // const citydata = [
    //     'mumbai',
    //     'bangalore',
    //     'jaipur',
    //     'chennai',
    //     'orrisa',
    //     'goa',
    // ];
    const handleSelectChange = (e) => {
        console.log('ok ', e);
        setselectvalue(e)
    }
    const handleAddCitySubmit = async () => {
        if (inpcity === "") {

            openNotificationWithIcon('warning', 'Warning', 'City field cannot be empty')

            // alert('ok')
        } else {
            try {
                setloading(true)
                const result = await postapi("api/addcity", { name: inpcity })
                // console.log('res  ', result)
                if (result.created) {
                    setcitydata(items => [...items, inpcity])
                    setcitydropdown(i => [...i, { label: inpcity, value: inpcity }])
                    setloading(false)
                    setinpcity("")
                    setaddcityModal(false)
                    openNotificationWithIcon('success', 'Successful', 'New city has been added')
                } else {
                    setloading(false)
                    setinpcity("")
                    setaddcityModal(false)
                    openNotificationWithIcon('error', 'Failed', 'City is already present')
                }
            } catch (e) {
                console.log('err ', e);
                setinpcity("")
                setloading(false)
                setaddcityModal(false)
                openNotificationWithIcon('error', 'Failed', 'Some error occured')
            }
        }


    }
    const handleAddDomainSubmit = async () => {
        console.log('inpdomain ', inpdomain, ' slect ', selectvalue);

        if (inpdomain === "" || selectvalue === "") {
            openNotificationWithIcon('warning', 'Warning', 'Some fields are missing')
        } else {
            try {
                setloading(true)
                const result = await postapi('api/addomain', { 'cityname': selectvalue, 'name': inpdomain })
                if (result.created) {
                    setdomaindata(item => [...item, inpdomain])
                    setinpdomain("")
                    openNotificationWithIcon('success', 'Successful', 'New domain has been added')
                    setloading(false)
                    setaddomainMdoal(false)
                } else {
                    openNotificationWithIcon('error', 'Failed', 'Domain is already present')
                    setinpdomain("")
                    setloading(false)
                    setaddomainMdoal(false)
                }
            } catch (err) {
                console.log('err ', err);
                setinpcity("")
                setloading(false)
                setaddomainMdoal(false)
                openNotificationWithIcon('error', 'Failed', 'Some error occured')
            }

        }
    }
    const handleAddSocialSubmit = async () => {
        if (inpsocial === "") {
            openNotificationWithIcon('warning', 'Warning', 'Social media field cannot be empty')
        } else {
            try {
                setloading(true)
                const result = await postapi('api/social/addsocial', { 'social_name': inpsocial })
                if (result.created) {
                    openNotificationWithIcon('success', 'Successful', 'New social media has been added')
                    setSocialData(item => [...item, inpsocial])
                    setinpsocial("")
                    setloading(false)
                    setaddSocialMdoal(false)
                } else {
                    setinpsocial("")
                    setloading(false)
                    setaddSocialMdoal(false)
                    openNotificationWithIcon('error', 'Failed', 'Social media already exist')
                }

            } catch (err) {
                console.log('err ', err);
                setinpsocial("")
                setloading(false)
                setaddSocialMdoal(false)
                openNotificationWithIcon('error', 'Failed', 'Some error occured')
            }
        }
    }
    const handleAddCityModal = () => {
        setaddcityModal(!addcityModal)
    }
    const handleDomainModal = () => {
        setaddomainMdoal(!adddomainModal)
    }
    const handleAddSocialModal = () => {
        setaddSocialMdoal(!adddsocialModal)
    }
    const onDeleteCity = async (item) => {

        console.log(item)
        try {
            const result = await deleteapi('api/deletecity', { name: item });
            if (result.deleted) {
                openNotificationWithIcon('success', 'Successfully deleted')
                const cd = citydata.filter(i => i !== item)
                setcitydata(cd)
            } else {
                openNotificationWithIcon('error', 'Unable to delete')
            }
        } catch (er) {
            openNotificationWithIcon('error', 'Error occured while deleting')
        }


    }
    const ondeletedomain = async (item) => {
        console.log(item)
        try {
            const result = await deleteapi('api/deletedomain', { name: item });
            if (result.deleted) {
                openNotificationWithIcon('success', 'Domain Successfully deleted')
                const cd = domaindata.filter(i => i !== item)
                setdomaindata(cd)
            } else {
                openNotificationWithIcon('error', 'Unable to delete')
            }
        } catch (er) {
            openNotificationWithIcon('error', 'Error occured while deleting')
        }


    }
    const ondeletesocial = async (item) => {
        console.log(item)
        try {
            const result = await deleteapi('api/social/deletesocial', { social_name: item });
            console.log('res ', result);
            if (result.deleted) {
                openNotificationWithIcon('success', 'Social Successfully deleted')
                const cd = socialdata.filter(i => i !== item)
                setSocialData(cd)
            } else {
                openNotificationWithIcon('error', 'Unable to delete')
            }
        } catch (er) {
            console.log('onDeletesocial ', er);

            openNotificationWithIcon('error', 'Error occured while deleting')
        }

    }
    const getcitieswithdomain = async () => {
        try {
            const cities = await getapis('api/getcitieswithdomains');
            const gc = cities.map(item => item.domains);
            setdomaindata([].concat(...gc));

        } catch (e) {
            console.log(e);
            setloading(false)
            // alert("Error getting cities");           
            openNotificationWithIcon('error', 'Failed to load domain data')
        }

    }
    const getcitiedata = async () => {
        try {
            const cl = await getapis('api/getcities');
            const d = await cl.result.map(item => [{ value: item.name, label: item.name }])
            const l = await d.flat(1).map(item => item.value)
            setcitydropdown(d.flat(1));
            setcitydata(l);
            setloading(false)
        } catch (e) {
            console.log(e);
            setloading(false)
            openNotificationWithIcon('error', 'Failed to load city data')
        }
    }
    const getsocial = async () => {
        try {
            const socials = await getapis('api/social/getsocial');
            const gs = await socials.map(item => item.social_name);
            setSocialData(gs)
            setloading(false)
        } catch (e) {
            console.log(e);
            setloading(false)
            openNotificationWithIcon('error', 'Failed to load social data')
        }
    }
    useEffect(() => {
        console.log('render');
        setloading(true)
        getcitiedata()
        getcitieswithdomain()
        getsocial()
    }, [])

    return (
        <div>
            {contextHolder}
            <Bcrumb id={'Dashboard'} location={'Admin'} />
            <Divider />
            <Row gutter={[16, 6]} >
                <Col md={8} sm={12} xs={24} >
                    <Card
                        loading={loading}
                        onClick={handleAddCityModal}
                        title="City"
                        bordered={false}

                        extra={<Button type="primary" icon={<PlusCircleOutlined />} block>
                            Add City
                        </Button>}
                    >
                        <Statistic title="" value={citydata.length} valueStyle={{ color: '#3f8600' }} />

                    </Card>
                </Col>
                <Col md={8} sm={12} xs={24} >
                    <Card
                        loading={loading}
                        onClick={handleDomainModal}
                        title="Domain"
                        bordered={false}

                        extra={<Button type="primary" icon={<PlusCircleOutlined />} block>
                            Add Domain
                        </Button >}
                    >
                        <Statistic title="" value={domaindata.length} valueStyle={{ color: '#3f8600' }} />

                    </Card>
                </Col>
                <Col md={8} sm={12} xs={24} >
                    <Card
                        loading={loading}
                        onClick={handleAddSocialModal}
                        title="Social"
                        bordered={false}

                        extra={<Button type="primary" icon={<PlusCircleOutlined />} block>
                            Add Social
                        </Button>}
                    >
                        <Statistic title="" value={socialdata.length} valueStyle={{ color: '#3f8600' }} />
                    </Card>
                </Col>


            </Row>
            <Divider />
            <Row gutter={16} >
                <Col md={10} sm={6} xs={24}>
                    <List
                        loading={loading}
                        size="small"
                        header={<h2>All cities</h2>}
                        bordered

                        style={{ backgroundColor: '#fff' }}
                        dataSource={citydata}
                        renderItem={(item) => <List.Item
                            actions={[<Button type="link">Edit</Button>,
                            <Popconfirm
                                title="Are you sure to delete this city?"
                                onConfirm={() => onDeleteCity(item)}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger type="link">Delete</Button>
                            </Popconfirm>
                            ]}

                        >{item}</List.Item>}
                    />
                </Col>
                <Col md={14} sm={12} xs={24}>
                    <List
                        loading={loading}
                        size="small"
                        header={<h2>All domains</h2>}
                        // footer={<div>Footer</div>}
                        bordered

                        style={{ backgroundColor: '#fff' }}
                        dataSource={domaindata}
                        renderItem={(item) => <List.Item actions={[<Button type="link">Edit</Button>,

                        <Popconfirm
                            title="Are you sure to delete this domain?"
                            onConfirm={() => ondeletedomain(item)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type="link">Delete</Button>
                        </Popconfirm>
                        ]}>{item}</List.Item>}
                    />
                </Col>
                <Col md={10} sm={6} xs={24}>
                    <List
                        loading={loading}
                        size="small"
                        header={<h2>All social types</h2>}
                        // footer={<div>Footer</div>}
                        bordered

                        style={{ backgroundColor: '#fff' }}
                        dataSource={socialdata}

                        renderItem={(item) => <List.Item actions={[<Button type="link">Edit</Button>, <Popconfirm
                            title="Are you sure to delete this social?"
                            onConfirm={() => ondeletesocial(item)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type="link">Delete</Button>
                        </Popconfirm>]}>{item}</List.Item>}
                    />
                </Col>
            </Row>
            <Modal title="Add city" open={addcityModal} onOk={handleAddCitySubmit} onCancel={handleAddCityModal} confirmLoading={loading}>
                <Divider />
                <Input placeholder="City name" prefix={<FileAddOutlined />} value={inpcity} onChange={(e) => setinpcity(e.target.value)} />
            </Modal>
            <Modal title="Add domain" open={adddomainModal} onOk={handleAddDomainSubmit} onCancel={handleDomainModal} confirmLoading={loading}>
                <Divider />
                <Select
                    showSearch
                    style={{ width: 120 }}
                    optionFilterProp="children"
                    defaultValue="Please select city"
                    onChange={handleSelectChange}
                    options={citydropdown}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                />
                <Input placeholder="Domain" onChange={(e) => setinpdomain(e.target.value)} value={inpdomain} prefix={<CodeOutlined />} style={{ marginTop: 10 }} />
            </Modal>
            <Modal title="Add Social Type" open={adddsocialModal} onOk={handleAddSocialSubmit} onCancel={handleAddSocialModal} confirmLoading={loading}>
                <Divider />
                <Input placeholder="Social Name" value={inpsocial} onChange={(e) => setinpsocial(e.target.value)} prefix={<DingdingOutlined />} style={{ marginTop: 10 }} />
            </Modal>
        </div>
    )
}
export default memo(AdminPage)